// import React, { useEffect, useState } from "react";
// import { Row, Col, Container, Table } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
// import { Link } from "react-router-dom";
// import logo from "./../images/logo.png";
// import manufacturearrow from "./../images/manufacturearrow.png";
// import expiryarrow from "./../images/expiryarrow.png";
// import moment from "moment";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import "./../pages/Home.css";

// export default function Home() {
//   const firstDay = new Date(moment().subtract(30, "days"));
//   const lastDay = new Date(moment());
//   const [locations, setLocations] = useState([]);
//   const [selectedLocation, setSelectedLocation] = useState("Chennai");
//   const [error, setError] = useState("");

//   const icons = (
//     <svg xmlns="http://www.w3.org/2000/svg" fill="#45c7ff" viewBox="0 0 24 24">
//       <path d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" />
//     </svg>
//   );

//   const [sampleData, setSampleData] = useState({});
//   const [filterDate, setFilterDate] = useState(new Date());

//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const fetchUserData = () => {
//     const finalDate = moment(filterDate).format("DD-MM-YYYY");
//     getJSON(finalDate);
//   };

//   async function getJSON(finalDate) {
//     try {
//       const response = await
//       // fetch(
//       //   `https://api.teamwater.in/api/v2/water-tests/latest?date=${finalDate}&location=${
//       //     selectedLocation ? selectedLocation : ""
//       //   }`
//       // )
//       fetch(
//         `http://192.168.1.7:3700/api/v2/water-tests/latest?date=${finalDate}&location=${
//           selectedLocation ? selectedLocation : "Chennai"
//         }`
//       );
//       const data = await response.json();
//       if (data?.data) {
//         setSampleData(data.data);
//         setError();
//       } else {
//         setSampleData({});
//         setError(data.errors);
//       }
//     } catch (error) {
//       console.error("API Error:", error);
//       setSampleData({});
//     }
//   }

//   async function fetchLocationData() {
//     try {
//       const response = await
//       // fetch(
//       //   `https://api.teamwater.in/api/v2/users/location`
//       // )
//       fetch(
//         `http://192.168.1.7:3700/api/v2/users/location`
//       );
//       const data = await response.json();
//       if (data?.data) {
//         setLocations(data.data);
//       } else {
//         setLocations([]);
//       }
//     } catch (error) {
//       console.error("Location API Error:", error);
//     }
//   }

//   const formatData = (data) => {
//     return data ? moment(data).format("DD-MM-YYYY") : "-";
//   };

//   useEffect(() => {
//     fetchLocationData();
//     fetchUserData();
//   }, [filterDate, selectedLocation]);
//   console.log(error);
//   return (
//     <div className="entiresection-wrapper">
//       <div className="header-wrapper">
//         <Container>
//           <Row className="justify-content-center">
//             <img src={logo} alt="Logo" />
//           </Row>
//         </Container>
//       </div>
//       <p className="test-report-title">QUALITY STANDARDS</p>
//       <div className="d-flex justify-content-center align-item-center">
//         <section className="ml-4 d-flex flex-column align-items-center">
//           <form className="mb-2">
//             <select
//               name="location"
//               id="location"
//               placeholder="Select the location"
//               onChange={(e) => setSelectedLocation(e.target.value)}
//               value={selectedLocation}
//             >
//               <option value="" disabled>
//                 Select Location
//               </option>
//               {locations.map((value, index) => (
//                 <option key={index} value={value}>
//                   {value}
//                 </option>
//               ))}
//             </select>
//           </form>
//           {error && <span style={{ color: "red" }}>{error}</span>}
//         </section>
//       </div>

//       <div className="table-wrapper">
//         <Container>
//           <Row>
//             <Table>
//               <thead>
//                 <tr>
//                   <th>Parameters</th>
//                   <th>Values</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td>TDS</td>
//                   <td>{sampleData?.tds ?? "-"}</td>
//                 </tr>
//                 <tr>
//                   <td>pH Value</td>
//                   <td>{sampleData?.phValue ?? "-"}</td>
//                 </tr>
//                 <tr>
//                   <td>Calcium</td>
//                   <td>{sampleData?.calcium ?? "-"}</td>
//                 </tr>
//                 <tr>
//                   <td>Magnesium</td>
//                   <td>{sampleData?.magnesium ?? "-"}</td>
//                 </tr>
//                 <tr>
//                   <td>Water Test Report Download</td>
//                   <td>
//                     {sampleData?.waterTestReportFileName ? (
//                       <Button
//                         onClick={() =>
//                           window.open(
//                             `https://api.teamwater.in/${sampleData.waterTestReportFileName}`
//                           )
//                         }
//                         size="sm"
//                         variant="primary"
//                       >
//                         Report
//                       </Button>
//                     ) : (
//                       "-"
//                     )}
//                   </td>
//                 </tr>
//               </tbody>
//             </Table>
//           </Row>
//           <Row className="justify-content-center link-wrapper">
//             <Link to="https://www.teamwater.in/" target="_blank">
//               teamwater.in
//             </Link>
//           </Row>
//         </Container>
//       </div>
//       <div className="testing-report-wrapper">
//         <Container>
//           <Row className="justify-content-center report-container">
//             <Col sm={6} xs={6}>
//               <div className="manufacture-date manufacture-container">
//                 <span>
//                   <img src={manufacturearrow} className="m-2" alt="Arrow" />
//                 </span>
//                 <p>Manufacturing Date : </p>
//                 <h4>{formatData(sampleData?.manufacturerDate)}</h4>
//               </div>
//             </Col>
//             <Col sm={6} xs={6}>
//               <div className="manufacture-date expiry-container">
//                 <span>
//                   <img src={expiryarrow} className="m-2" alt="Arrow" />
//                 </span>
//                 <p>Expiry Date: </p>
//                 <h4>{formatData(sampleData?.expiryDate)}</h4>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//       <div className="testing-report-wrapper">
//         <Container>
//           <Row className="justify-content-center">
//             <Col>
//               <div
//                 style={{
//                   textAlign: "center",
//                   marginTop: "18px",
//                   marginBottom: "9px",
//                 }}
//               >
//                 <DatePicker
//                   showIcon
//                   wrapperClassName="datePicker"
//                   minDate={firstDay}
//                   maxDate={lastDay}
//                   selected={filterDate}
//                   dateFormat="dd-MM-yyyy"
//                   onChange={(date) => setFilterDate(date)}
//                   icon={icons}
//                 />
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </div>
//       <div className="modal-wrapper">
//         <Container>
//           <Button size="sm" variant="primary" onClick={handleShow}>
//             Note
//           </Button>

//           <Modal show={show} onHide={handleClose}>
//             <Modal.Header closeButton>
//               <Modal.Title>Range Variable</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//               <p>
//                 <b>pH :</b> 6.0 - 8.5
//               </p>
//               <p>
//                 <b>TDS Above :</b> 75 -90 mg/l
//               </p>
//               <p>
//                 <b>If any queries</b>
//                 <div>
//                   <a href="mailto:teamwatermktg@drraoholdings.com">
//                     teamwatermktg@drraoholdings.com
//                   </a>
//                 </div>
//                 <a href="tel:044-24353954">044-24353954</a>
//               </p>
//             </Modal.Body>
//             <Modal.Footer>
//               <Button size="sm" variant="primary" onClick={handleClose}>
//                 Close
//               </Button>
//             </Modal.Footer>
//           </Modal>
//         </Container>
//       </div>
//       <div className="copyright-section">
//         <Container>
//           <p>
//             Developed by&nbsp;
//             <Link to="https://www.webdads2u.com/" target="_blank">
//               WEBDADS2U PVT LTD
//             </Link>
//           </p>
//         </Container>
//       </div>
//     </div>
//   );
// }
// ================================================================================================================
// ================================================================================================================
// ================================================================================================================
// ================================================================================================================
// ================================================================================================================
// ================================================================================================================
// ================================================================================================================
// ================================================================================================================
// import React, { useEffect, useState } from "react";
// import { Row, Col, Container, Table } from "react-bootstrap";
// import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
// import { Link } from "react-router-dom";
// import logo from "./../images/logo.png";
// import manufacturearrow from "./../images/manufacturearrow.png";
// import expiryarrow from "./../images/expiryarrow.png";
// import moment from "moment";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import "./../pages/Home.css";

// export default function Home() {
//   const firstDay = new Date(moment().subtract(30, "days"));
//   const lastDay = new Date(moment());
//   const [locations, setLocations] = useState([]);
//   const [selectedLocation, setSelectedLocation] = useState("");
//   const [error, setError] = useState(null);

//   const icons = (
//     <svg xmlns="http://www.w3.org/2000/svg" fill="#45c7ff" viewBox="0 0 24 24">
//       <path d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" />
//     </svg>
//   );

//   const [sampleData, setSampleData] = useState(null);
//   const [filterDate, setFilterDate] = useState(null); // Initialize as null

//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const fetchUserData = () => {
//     if (filterDate && selectedLocation) {
//       const finalDate = moment(filterDate).format("DD-MM-YYYY");
//       getJSON(finalDate);
//     }
//   };

//   async function getJSON(finalDate) {
//     try {
//       const response = await fetch(
//         // `http://192.168.1.7:3700/api/v2/water-tests/latest?date=${finalDate}&location=${selectedLocation}`
//         `https://api.teamwater.in/api/v2/water-tests/latest?date=${finalDate}&location=${selectedLocation}`
//       );
//       const data = await response.json();
//       if (data?.data) {
//         setSampleData(data.data);
//         setError(null);
//       } else {
//         setSampleData(null);
//         // setError(data.errors + ` on ${finalDate}  at this ${selectedLocation} location` || "No data available");
//         setError(
//           `Water test results are not available for ${selectedLocation} on ${finalDate}. Please select a different date or location.` ||
//             "No data available"
//         );
//       }
//     } catch (error) {
//       setSampleData(null);
//       setError("API Error");
//     }
//   }

//   async function fetchLocationData() {
//     try {
//       const response = await fetch(
//         // `http://192.168.1.7:3700/api/v2/users/location`
//         `https://api.teamwater.in/api/v2/users/location`
//       );
//       const data = await response.json();
//       if (data?.data) {
//         setLocations(data.data);
//       } else {
//         setLocations([]);
//       }
//     } catch (error) {
//       console.error("Location API Error:", error);
//     }
//   }

//   const formatData = (data) => {
//     return data ? moment(data).format("DD-MM-YYYY") : "-";
//   };

//   useEffect(() => {
//     fetchLocationData();
//   }, []);

//   useEffect(() => {
//     fetchUserData();
//   }, [filterDate, selectedLocation]);

//   return (
//     <div className="entiresection-wrapper">
//       <div className="header-wrapper">
//         <Container>
//           <Row className="justify-content-center">
//             <img src={logo} alt="Logo" />
//           </Row>
//         </Container>
//       </div>
//       <p className="test-report-title">QUALITY STANDARDS</p>
//       <div className="table-wrapper">
//         <Container>
//           <Row>
//             <Table>
//               <thead>
//                 <tr>
//                   <th>Parameters</th>
//                   <th>Values</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 <tr>
//                   <td>TDS</td>
//                   <td>{sampleData?.tds ?? "-"}</td>
//                 </tr>
//                 <tr>
//                   <td>pH Value</td>
//                   <td>{sampleData?.phValue ?? "-"}</td>
//                 </tr>
//                 <tr>
//                   <td>Calcium</td>
//                   <td>{sampleData?.calcium ?? "-"}</td>
//                 </tr>
//                 <tr>
//                   <td>Magnesium</td>
//                   <td>{sampleData?.magnesium ?? "-"}</td>
//                 </tr>
//                 <tr>
//                   <td>
//                     {" "}
//                     <Button size="sm" variant="primary" onClick={handleShow}>
//                       Note
//                     </Button>
//                   </td>
//                 </tr>
//               </tbody>
//             </Table>
//           </Row>
//           <Row className="justify-content-center link-wrapper">
//             <Link to="https://www.teamwater.in/" target="_blank">
//               teamwater.in
//             </Link>
//           </Row>
//         </Container>
//       </div>
//       <div className="d-flex justify-content-center align-item-center">
//         <div className="testing-report-wrapper">
//           <Container>
//             <Row className="justify-content-center">
//               <Col>
//                 <div className="main-div">
//                   <section className="ml-2 d-flex flex-column align-items-center">
//                     <label htmlFor="datePicker">Select Date</label>
//                     <DatePicker
//                       id="datePicker"
//                       showIcon
//                       wrapperClassName="datePicker"
//                       minDate={firstDay}
//                       maxDate={lastDay}
//                       selected={filterDate}
//                       dateFormat="dd-MM-yyyy"
//                       onChange={(date) => setFilterDate(date)}
//                       icon={icons}
//                       placeholderText="Select a date" // Add placeholder text
//                     />
//                   </section>
//                   <section className="ml-4 d-flex flex-column align-items-center">
//                     <label htmlFor="location" className="mt-1">
//                       Select Location
//                     </label>
//                     <form className="mb-2">
//                       <select
//                         name="location"
//                         id="location"
//                         className="location-select"
//                         onChange={(e) => setSelectedLocation(e.target.value)}
//                         value={selectedLocation}
//                       >
//                         <option value="" disabled>
//                           Select Location
//                         </option>
//                         {locations.map((value, index) => (
//                           <option key={index} value={value}>
//                             {value}
//                           </option>
//                         ))}
//                       </select>
//                     </form>
//                   </section>
//                 </div>
//                 <section className="ml-4 d-flex flex-column align-items-center justify-content-center">
//                   {error && (
//                     <span
//                       style={{
//                         color: "red",
//                         textAlign: "center",
//                         fontSize: "13px",
//                       }}
//                     >
//                       {error}
//                     </span>
//                   )}
//                 </section>
//               </Col>
//             </Row>
//           </Container>
//         </div>
//       </div>

//       <div className="testing-report-wrapper">
//         <Container>
//           <Row className="justify-content-center report-container">
//             <Col sm={6} xs={6}>
//               <div className="manufacture-date manufacture-container">
//                 <span>
//                   <img src={manufacturearrow} className="m-2" alt="Arrow" />
//                 </span>
//                 <p>Manufacturing Date : </p>
//                 <h4>{formatData(sampleData?.manufacturerDate)}</h4>
//               </div>
//             </Col>
//             <Col sm={6} xs={6}>
//               <div className="manufacture-date expiry-container">
//                 <span>
//                   <img src={expiryarrow} className="m-2" alt="Arrow" />
//                 </span>
//                 <p>Expiry Date: </p>
//                 <h4>{formatData(sampleData?.expiryDate)}</h4>
//               </div>
//             </Col>
//           </Row>
//         </Container>
//       </div>

//       <div className="table-wrapper ">
//         <Container>
//           <Row>
//             <Table className="m-2">
//               <tbody>
//                 <tr>
//                   <td style={{ width: "58%" }}>Water Test Report Download</td>
//                   <td>
//                     {sampleData?.waterTestReportFileName ? (
//                       <Button
//                         onClick={() =>
//                           window.open(
//                             `https://api.teamwater.in/${sampleData.waterTestReportFileName}`
//                           )
//                         }
//                         size="sm"
//                         variant="primary"
//                       >
//                         Report
//                       </Button>
//                     ) : (
//                       "-"
//                     )}
//                   </td>
//                 </tr>
//               </tbody>
//             </Table>
//           </Row>
//         </Container>
//       </div>

//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Range Variable</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>
//             <b>pH :</b> 6.0 - 8.5
//           </p>
//           <p>
//             <b>TDS Above :</b> 75 -90 mg/l
//           </p>
//           <p>
//             <b>If any queries</b>
//             <div>
//               <a href="mailto:teamwatermktg@drraoholdings.com">
//                 teamwatermktg@drraoholdings.com
//               </a>
//             </div>
//             <a href="tel:044-24353954">044-24353954</a>
//           </p>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button size="sm" variant="primary" onClick={handleClose}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//       {/* </Container> */}
//       {/* </div> */}
//       <div className="copyright-section">
//         <Container>
//           <p>
//             Developed by&nbsp;
//             <Link to="https://www.webdads2u.com/" target="_blank">
//               WEBDADS2U PVT LTD
//             </Link>
//           </p>
//         </Container>
//       </div>
//     </div>
//   );
// }
//==============================================================================================================================================
//==============================================================================================================================================
//==============================================================================================================================================
//==============================================================================================================================================
//==============================================================================================================================================
//==============================================================================================================================================
//==============================================================================================================================================
//==============================================================================================================================================
//==============================================================================================================================================
//==============================================================================================================================================
//==============================================================================================================================================
import React, { useEffect, useState } from "react";
import { Row, Col, Container, Table } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import logo from "./../images/logo.png";
import manufacturearrow from "./../images/manufacturearrow.png";
import expiryarrow from "./../images/expiryarrow.png";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./../pages/Home.css";

export default function Home() {
  const firstDay = moment().subtract(30, "days").toDate();
  const lastDay = moment().toDate();
  const [locations, setLocations] = useState(["Chennai"]); // Default location
  const [selectedLocation, setSelectedLocation] = useState("Chennai");
  const [error, setError] = useState(null);

  const icons = (
    <svg xmlns="http://www.w3.org/2000/svg" fill="#45c7ff" viewBox="0 0 24 24">
      <path d="M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z" />
    </svg>
  );

  const [sampleData, setSampleData] = useState({
    tds: "75 - 90",
    phValue: "6.0 - 8.5",
    calcium: "10 - 75",
    magnesium: "5 - 30",
    manufacturerDate: moment().format("DD-MM-YYYY"), // Default to current date
    expiryDate: moment().add(3, "days").format("DD-MM-YYYY"), // Default to current date + 3 days
  }); // Default values

  const [filterDate, setFilterDate] = useState(lastDay); // Default to current date

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchUserData = () => {
    if (filterDate && selectedLocation) {
      const finalDate = moment(filterDate).format("DD-MM-YYYY");
      getJSON(finalDate);
    }
  };

  async function getJSON(finalDate) {
    try {
      const response = await fetch(
        // `http://192.168.1.7:3700/api/v2/water-tests/latest?date=${finalDate}&location=${selectedLocation}`
        `https://api.teamwater.in/api/v2/water-tests/latest?date=${finalDate}&location=${selectedLocation}`
      );
      const data = await response.json();
      if (data?.data) {
        setSampleData({
          ...data.data,
          manufacturerDate: moment(data.data.manufacturerDate).format(
            "DD-MM-YYYY"
          ),
          expiryDate: moment(data.data.expiryDate).format("DD-MM-YYYY"),
        });
        setError(null);
      }
      // else {
      //   setError(
      //     `Water test results are not available for ${selectedLocation} on ${finalDate}. Please select a different date or location.` ||
      //       "No data available"
      //   );
      // }
    } catch (error) {
      setError("API Error");
    }
  }

  async function fetchLocationData() {
    try {
      const response = await fetch(
        // `http://192.168.1.7:3700/api/v2/users/location`
        `https://api.teamwater.in/api/v2/users/location`
      );
      const data = await response.json();
      if (data?.data) {
        setLocations(data.data);
      } else {
        setLocations([]);
      }
    } catch (error) {
      console.error("Location API Error:", error);
    }
  }

  const formatData = (data) => {
    return data ? moment(data, "DD-MM-YYYY").format("DD-MM-YYYY") : "-";
  };

  useEffect(() => {
    fetchLocationData();
  }, []);

  useEffect(() => {
    fetchUserData();
  }, [filterDate, selectedLocation]);

  return (
    <div className="entiresection-wrapper">
      <div className="header-wrapper">
        <Container>
          <Row className="justify-content-center">
            <img src={logo} alt="Logo" />
          </Row>
        </Container>
      </div>
      <p className="test-report-title">QUALITY STANDARDS</p>
      <div className="table-wrapper">
        <Container>
          <Row>
            <Table>
              <thead>
                <tr>
                  <th>Parameters</th>
                  <th>Values</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>TDS</td>
                  <td>{sampleData?.tds ?? "-"}</td>
                </tr>
                <tr>
                  <td>pH Value</td>
                  <td>{sampleData?.phValue ?? "-"}</td>
                </tr>
                <tr>
                  <td>Calcium</td>
                  <td>{sampleData?.calcium ?? "-"}</td>
                </tr>
                <tr>
                  <td>Magnesium</td>
                  <td>{sampleData?.magnesium ?? "-"}</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    <Button size="sm" variant="primary" onClick={handleShow}>
                      Note
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
          <Row className="justify-content-center link-wrapper">
            <Link to="https://www.teamwater.in/" target="_blank">
              teamwater.in
            </Link>
          </Row>
        </Container>
      </div>
      <div className="d-flex justify-content-center align-item-center">
        <div className="testing-report-wrapper">
          <Container>
            <Row className="justify-content-center">
              <Col>
                <div className="main-div">
                  <section className="ml-2 d-flex flex-column align-items-center">
                    <label htmlFor="datePicker">Select Date</label>
                    <DatePicker
                      id="datePicker"
                      showIcon
                      wrapperClassName="datePicker"
                      minDate={firstDay}
                      maxDate={lastDay}
                      selected={filterDate}
                      dateFormat="dd-MM-yyyy"
                      onChange={(date) => {
                        setFilterDate(date);
                        setSampleData((prevData) => ({
                          ...prevData,
                          manufacturerDate: moment(date).format("DD-MM-YYYY"),
                          expiryDate: moment(date)
                            .add(3, "days")
                            .format("DD-MM-YYYY"),
                        }));
                      }}
                      icon={icons}
                      placeholderText="Select a date" // Add placeholder text
                    />
                  </section>
                  <section className="ml-4 d-flex flex-column align-items-center">
                    <label htmlFor="location" className="mt-1">
                      Select Location
                    </label>
                    <form className="mb-2">
                      <select
                        name="location"
                        id="location"
                        className="location-select"
                        onChange={(e) => setSelectedLocation(e.target.value)}
                        value={selectedLocation}
                      >
                        <option value="" disabled>
                          Select Location
                        </option>
                        {locations.map((value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </form>
                  </section>
                </div>
                <section className="ml-4 d-flex flex-column align-items-center justify-content-center">
                  {error && (
                    <span
                      style={{
                        color: "red",
                        textAlign: "center",
                        fontSize: "13px",
                      }}
                    >
                      {error}
                    </span>
                  )}
                </section>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className="testing-report-wrapper">
        <Container>
          <Row className="justify-content-center report-container">
            <Col sm={6} xs={6}>
              <div className="manufacture-date manufacture-container">
                <span>
                  <img src={manufacturearrow} className="m-2" alt="Arrow" />
                </span>
                <p>Manufacturing Date : </p>
                <h4>{formatData(sampleData?.manufacturerDate)}</h4>
              </div>
            </Col>
            <Col sm={6} xs={6}>
              <div className="manufacture-date expiry-container">
                <span>
                  <img src={expiryarrow} className="m-2" alt="Arrow" />
                </span>
                <p>Expiry Date: </p>
                <h4>{formatData(sampleData?.expiryDate)}</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="table-wrapper ">
        <Container>
          <Row>
            <Table className="m-2">
              <tbody>
                <tr>
                  <td style={{ width: "58%" }}>Water Test Report Download</td>
                  <td>
                    {sampleData?.waterTestReportFileName ? (
                      <Button
                        onClick={() =>
                          window.open(
                            `https://api.teamwater.in/${sampleData.waterTestReportFileName}`
                          )
                        }
                        size="sm"
                        variant="primary"
                      >
                        Report
                      </Button>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </Container>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Range Variable</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>pH :</b> 6.0 - 8.5
          </p>
          <p>
            <b>TDS Above :</b> 75 -90 mg/l
          </p>
          <p>
            <b>If any queries</b>
            <div>
              <a href="mailto:teamwatermktg@drraoholdings.com">
                teamwatermktg@drraoholdings.com
              </a>
            </div>
            <a href="tel:044-24353954">044-24353954</a>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="copyright-section">
        <Container>
          <p>
            Developed by&nbsp;
            <Link to="https://www.webdads2u.com/" target="_blank">
              WEBDADS2U PVT LTD
            </Link>
          </p>
        </Container>
      </div>
    </div>
  );
}
